let $header = document.getElementById('header');
let $hamburger = document.getElementById('hamburger');
let $search = document.getElementById('search');
let $hero = document.getElementById('hero');
let $serviceItems = document.querySelectorAll('.services__list__item');

if (window.innerWidth < 1280) {
    $serviceItems.forEach((element) => {
        element.dataset.wowDelay = '0,4s';
    });
}

window.onload = function () {
    let linkFonts = document.createElement('link');
    linkFonts.rel = 'stylesheet';
    linkFonts.href = './assets/styles/fonts.css';
    document.head.appendChild(linkFonts);
    let linkAnimate = document.createElement('link');
    linkAnimate.rel = 'stylesheet';
    linkAnimate.href = './assets/styles/animate.min.css';
    document.head.appendChild(linkAnimate);

    $search.addEventListener('click', function (e) {
        $search.parentElement.classList.toggle('active');
    });

    $hamburger.addEventListener('click', function (e) {
        $header.classList.toggle('active');
        $hamburger.classList.toggle('active');
        document.body.classList.toggle('no-scroll');
    });

    if (window.innerWidth > 1280) {
        if ($hero !== null) {
            lazyLoadVideo();
        }
        if (document.getElementById('slider')) {
            slider('slider', 5, 'flip', '2s');
        }
    }
};

// Custom Slider
function slider(slider, duration, amin, animTime) {
    let sliderId = slider || 'slider';
    let current = 4;
    let time = duration || 5;
    let effect = amin || 'show';
    let effectTime = animTime || '1s';
    let prevId = 'prev';
    let nextId = 'next';

    let count = document.getElementById(sliderId).childElementCount;

    let prev = document.getElementById(prevId);
    let next = document.getElementById(nextId);

    // Fuction Creation and Event Handleing
    prev.addEventListener('click', prevAction);
    next.addEventListener('click', nextAction);

    document.querySelectorAll('.projects__slider__slide').forEach((item, index) => {
        item.addEventListener('click', onClickAction);
    });

    function hideAll() {
        for (let i = 1; i <= count; i++) {
            let id = 'slide' + i;
            let slide = document.getElementById(sliderId).children[i - 1];
            slide.classList.remove('active');
            slide.classList.remove('animated');
            slide.classList.add('hide');
        }
    }

    function show(no, name, duretion) {
        hideAll();
        let slide = document.getElementById(sliderId).children[no - 1];
        slide.classList.remove('hide');
        slide.classList.add('active');

        setTimeout(function () {
            slide.classList.add('animated');
        }, 500);
    }

    function onClickAction() {
        hideAll();
        let slider = document.getElementById(sliderId);
        let num = 0;
        let array = Array.from(slider.children);

        array.forEach((item, index) => {
            if (item === this) {
                return (num = index + 1);
            }
        });

        let selected = document.getElementById(sliderId).children[num - 1];
        selected.classList.remove('hide');
        if (num > 4) {
            slider.insertBefore(selected, slider.children[3]);
        } else {
            slider.insertBefore(selected, slider.children[4]);
        }

        setTimeout(function () {
            selected.classList.add('active');
        }, 10);

        setTimeout(function () {
            selected.classList.add('animated');
        }, 500);
    }

    function prevAction() {
        let save = document.getElementById(sliderId).children;
        let item = Array.from(save).pop();

        if (current <= 1) {
            current = 3;
        }

        Array.from(save).pop().remove();
        document.getElementById(sliderId).prepend(item);

        show(current, effect, effectTime);
    }

    function nextAction() {
        let save = document.getElementById(sliderId).children;
        let item = Array.from(save).shift();

        if (current >= count) {
            current = 3;
        }

        Array.from(save).shift().remove();
        document.getElementById(sliderId).appendChild(item);

        show(current, effect, effectTime);
    }

    // First Time Init
    show(current, effect, effectTime);

    // Auto Slide Mode
    // setInterval(function auto() {
    //     if (current >= count) {
    //         current = 1;
    //     } else {
    //         current++;
    //     }
    //     show(current, effect, effectTime);
    // }, 1000 * time);
}

// Lazy
document.addEventListener('DOMContentLoaded', function () {
    let lazyloadImages = document.querySelectorAll('img.lazy');
    let lazyloadThrottleTimeout;

    function lazyload() {
        if (lazyloadThrottleTimeout) {
            clearTimeout(lazyloadThrottleTimeout);
        }

        lazyloadThrottleTimeout = setTimeout(function () {
            let scrollTop = window.pageYOffset;
            lazyloadImages.forEach(function (img) {
                if (img.offsetTop < window.innerHeight + scrollTop) {
                    img.src = img.dataset.src;
                    img.classList.remove('lazy');
                }
            });
            if (lazyloadImages.length == 0) {
                document.removeEventListener('scroll', lazyload);
                window.removeEventListener('resize', lazyload);
                window.removeEventListener('orientationChange', lazyload);
            }
        }, 20);
    }

    document.addEventListener('scroll', lazyload);
    window.addEventListener('resize', lazyload);
    window.addEventListener('orientationChange', lazyload);
});

function lazyLoadVideo() {
    let video, source;

    video = document.createElement('video');
    video.autoplay = true;
    video.muted = true;
    video.loop = true;

    source = document.createElement('source');
    source.src = './assets/books.mp4';
    source.type = 'video/mp4';

    $hero.prepend(video);
    video.append(source);
}

// Animation
wow = new WOW({
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 0,
    mobile: true,
    live: true,
});
wow.init();

// Popup
document.querySelectorAll('.popup-opener').forEach((btn) => {
    btn.addEventListener('click', function (e) {
        let $popup = document.querySelector('#' + btn.dataset.popup);
        document.querySelectorAll('.team-list__item').forEach((item) => {
            let popup = item.querySelector('.popup-wrapper');

            item.classList.remove('active');
            if (popup !== null) {
                item.querySelector('.popup-wrapper').classList.remove('active');
            }
        });

        $popup.classList.add('active');
        e.target.parentElement.classList.add('active');
    });
});

document.querySelectorAll('.popup-wrapper').forEach(($wrap) => {
    $wrap.addEventListener('click', () => {
        $wrap.classList.remove('active');
    });

    let $popup = $wrap.querySelector('#popup');

    $popup.addEventListener('click', (e) => {
        e.stopPropagation();
    });

    let $close = $popup.querySelector('.team-list__item__popup__close');

    if (!$close) {
        return;
    }

    $close.addEventListener('click', (e) => {
        $close.closest('.popup-wrapper').classList.remove('active');
        $close.closest('.popup-wrapper').closest('.team-list__item').classList.remove('active');
    });
});

// Swiper Slider
if (document.querySelector('.centered-slider') !== null) {
    let swiperCentered = new Swiper('.centered-slider .swiper', {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 20,
        loop: true,
        loopAdditionalSlides: 2,
        slideToClickedSlide: true,

        navigation: {
            nextEl: '.centered-slider .swiper-control__btn-next',
            prevEl: '.centered-slider .swiper-control__btn-prev',
        },

        breakpoints: {
            834: {
                spaceBetween: 40,
            },
        },
    });
}

if (document.querySelector('.pagination-slider') !== null) {
    let pagination = ['2003', '2005', '2006', '2015', '2018', '2020', '2021'];
    let swiperPagination = new Swiper('.pagination-slider .swiper', {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 100,

        navigation: {
            nextEl: '.pagination-slider .swiper-control__btn-next',
            prevEl: '.pagination-slider .swiper-control__btn-prev',
        },

        pagination: {
            el: '.pagination-slider .swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                return '<p class="' + className + '"><span>' + pagination[index] + '</span></p>';
            },
        },
    });
}

// If you click the control btns slider, the appearance of the block of markers will change
document.querySelectorAll('.pagination-slider .swiper-control__btn-next, .pagination-slider .swiper-control__btn-prev').forEach((item) => {
    item.addEventListener('click', (item) => {
        let bulletClientX = 0;
        document.querySelectorAll('.pagination-slider .swiper-pagination .swiper-pagination-bullet').forEach((bullet) => {
            if (bullet.classList.contains('swiper-pagination-bullet-active')) {
                bulletClientX = bullet.offsetLeft;
            }
        });
        document.querySelector('.pagination-slider .swiper-pagination').scrollLeft = bulletClientX - 168;
    });
});
